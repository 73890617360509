<script>
import LogoKotas from "../assets/landing-page/kotas_logo.svg";

export default {
  components: {
    LogoKotas
  }
};
</script>
<template>
  <section class="section-404">
    <div class="section-404__logo">
      <LogoKotas />
    </div>
    <div class="section-404__inner">
      <div class="section-404__bg">
        <h1 class="section-404__bg__title">404</h1>
        <h2 class="section-404__bg__description">
          Oops, página não encontrada.
        </h2>
        <div class="section-404__bg__animation"></div>
      </div>

      <div class="section-404__description">
        <button @click="$router.push('/')">Voltar para o início</button>
      </div>
    </div>
  </section>
</template>
<style scoped lang="scss">
@import "../styles/fonts";
@import "../styles/colors";

.section-404 {
  background: $p-branco;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section-404__logo svg {
  height: 30px;
  width: 120px;
}

.section-404__inner {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.section-404__bg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section-404__bg__animation {
  background-image: url(../assets/landing-page/gif/404.gif);
  background-position: center;
  height: 25rem;
  width: 100%;
}

.section-404__bg__title,
.section-404__bg__description {
  padding: 0;
  margin: 0;
  font-family: "Work Sans", sans-serif;
  color: rgb(54, 54, 54);
}

.section-404__bg__title {
  font-size: 6rem;
}

.section-404__bg__description {
  font-weight: normal;
  text-align: center;
  font-size: 1.5rem;
}

.section-404__description button {
  padding: 1rem 2rem;
  -webkit-transition: ease 0.25s;
  -o-transition: ease 0.25s;
  transition: ease 0.25s;
  font-family: $mulish;
  font-weight: 800;
}

@media (min-width: 468px) {
  .section-404__bg__animation {
    width: 50rem;
  }
}

@media (min-width: 1024px) {
  .section-404 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 2rem;
  }

  .section-404__bg__description {
    font-size: 2rem;
  }
}
</style>
